import React, { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'
import { init } from '@emailjs/browser';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';
import Form from 'react-bootstrap/Form';
import { useStaticQuery, graphql, Link } from "gatsby";
import PatentAnalyzerPic from '../images/patent-analyzer1.jpg';
import AnalyzerPic1 from '../images/analyzerpic1.png';
import AnalyzerPic2 from '../images/analyzerpic2.png';
import { constants } from '../utils/utils';
import TenLatestPost from '../utils/TenLatestPost';
init("user_zBTH4MMXVd61RJQjY4za1");

const getdata = graphql`
{
    allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "Patent Review"}}}}}
        sort: {order: DESC, fields: date}
    ) {
        nodes {
          excerpt
          id
          uri
          title
          slug
          date(fromNow: true)
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
        totalCount
      }
}`

const PatentAnalyzer = () => {
    const seo = { metaDesc: 'Patents, Trademarks, Copyrights, Trade Secrets, Provisional Patent, Utility Patent, USPTO' };
    const data = useStaticQuery(getdata);
    // console.log(data);
    const categories = data.allWpPost.nodes.reduce((acc, cur) => {
        return acc.concat(cur.categories.nodes.map((v) => v.name));
      }, []);
    
    const uniCategories = [...new Set(categories)].sort().filter(ele => ele !== constants().UNCATEGORIED);
    //
    const [isProcessing, setIsProcessing] = useState(false);
    const [isPatentListing, setIsPatentListing] = useState(false);
    const [method, setMethod] = useState('');
    const [patentText, setPatentText] = useState('');
    const [patentKeyword, setPatentKeyword] = useState('');
    const [patentTitle, setPatentTitle] = useState('');
    const [patentNumber, setPatentNumber] = useState(0);
    useEffect(() => {
        console.log("useEffect, set patent number", patentNumber);
        if (patentNumber && email) {
            startAnalyzer();
        }
      }, [patentNumber]);
    const [selectedCatName, setSelectedCatName] = useState('');
    const [email, setEmail] = useState('');
    const [results,setResults]=useState([]);
    const [patentListing, setPatentListing]=useState([]);
    
    /**
     * 
     * @param {*} e 
     */
    const changeMethodHandler = (e) => {
        setMethod(e.target.value);
        // reset values
        setPatentText('');
        setPatentKeyword('');
        setPatentNumber(0);
        setIsProcessing(false);
        setIsPatentListing(false);
        setResults([]);
        setPatentListing([]);
    }

    const getPatentListing = (e) => {
        e?.preventDefault();
        if (patentKeyword && email) {
            setIsPatentListing(true);
            // process
            var data = {
                authKey: "9cf87be465fadb672b24baa936340215",
                keyword: patentKeyword,
                email: email,
            };

            // call api
            fetch("https://api.trustyip.com/patents/analyse", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify(data)
              })
              .then(response => response.json())
              .then(response => {
                setIsPatentListing(false);
                setPatentListing(response.data);
              })
              .catch(err => {
                alert('There is something wrong. Please try again later!');
                setIsProcessing(false);
                setIsPatentListing(false);
                console.log(err);
              });
        } else {
            alert('Please fill in all fields.');
        }
    }

    const selectPatentNumberToAnalyzer = (number) => {
        setPatentText('');
        setPatentNumber(number);
        // useEffect(() => { setPatentNumber(number) }, []);
        console.log('log: ', patentNumber);
    }

    const backKeyword = (e) => {
        setPatentKeyword('');
        setIsPatentListing(false);
        setPatentListing([]);
    }

    /**
     * 
     * @param {*} e 
     */
    const startAnalyzer = (e) => {
        e?.preventDefault();
        console.log('startAnalyzer: ', patentText, patentNumber, email);
        if ((patentText || patentNumber) && email) {
            // set isProcessing;
            setIsProcessing(true);
            // process
            var data = {
                authKey: "9cf87be465fadb672b24baa936340215",
                email: email,
            };
            if (patentText) {
                data['text'] = patentText;
            } else {
                data['serial'] = patentNumber
            };
            // call api
            fetch("https://api.trustyip.com/patents/analyse", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify(data)
              })
              .then(response => response.json())
              .then(response => {
                const hasRefs = response.data?.hasRefs;
                if (!hasRefs || !hasRefs.length) {
                    if (patentText) {
                        alert("No Result. Please try again with other text.");
                    } else {
                        alert(`We are generating the report, please check your email inbox (${email}) including spam folder in a few minutes.`);
                    }
                }
                setResults(hasRefs || []);
                setIsProcessing(false);
              })
              .catch(err => {
                alert('There is something wrong. Please try again later!');
                setIsProcessing(false);
                console.log(err);
              });
        } else {
            if (!email || !patentText || (!patentNumber && patentNumber !== 0)) {
                alert('Please fill in all fields.');
            }
        }
    }

    const selectPatentToAnalyzer = (number, title, catName) => {
        setPatentNumber(number);
        setPatentTitle(title);
        setSelectedCatName(catName);
    }

    const unSelectPatentToAnalyzer = () => {
        setPatentNumber('');
        setPatentTitle('');
        setSelectedCatName('');
    }

    return (
        <Layout>
            <SEO title="Patent Analyzer"  canonical='/patent-analyzer' seo={seo} />
            <Header home={false}  menu='tools'/>
            <main>
                <section class="patent-banner-title py-5">
                    <div class="container">
                        <h2 class="page-title text-white">Patent Analyzer</h2>
                    </div>
                </section>
                <section className='py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <div>
                                    <h2 className='h3'>Guide To Patent Analyzer</h2>
                                    <div className='row'>
                                        <div className='col-md-8 col-sm-12'>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <h3>Benefit of using Patent Analyzer</h3>
                                                    <ul style={{paddingLeft: 1.2 + 'rem'}}>
                                                        <li>
                                                            <strong>Consistency and accuracy check</strong><br/>
                                                            <div>PatentAnalyzer™ can be used to verify the consistency and accuracy of your patent draft in terms of claims, literature, and terminology.</div>
                                                        </li>
                                                        <li className='mt-2'>
                                                            <strong>Analysis of publications or patents</strong><br/>
                                                            <div>Analyze public patent text documents to determine if your application is comparable to other patent applications.</div>
                                                        </li>
                                                        <li className='mt-2'>
                                                            <strong>Citation source search</strong><br/>
                                                            <div>You can search directly for patents and publications in your space and then analyze one or more of the patents/publications.</div>
                                                        </li>
                                                        <li className='mt-2'>
                                                            <strong>Research and comparison of claim components</strong><br/>
                                                            <div>Comparing claim components, terminology, and wording in multiple documents in patents or your document.</div>
                                                        </li>
                                                        <li className='mt-2'>
                                                            <strong>Wording and Terminology Check</strong><br/>
                                                            <div>A list of cases should be compiled that includes references to and interpretations of key terms 
                                                                and formulas within your patent document. PatentAnalyzer™ can find and report terminology using the user's dictionary.</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <h3>How patent analyzer work</h3>
                                                    <p>
                                                    We provide insight and analysis to assist our users in making informed decisions. 
                                                    Our machine learning (ML) stack allows us to build precise custom entity recognition models and extract insights 
                                                    from legal documents such as patent documents. We are constantly looking for new ways to apply natural language processing 
                                                    to improve our patent analytics.
                                                    </p>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <h3 className='mt-3'>Try out Patent Analyzer</h3>
                                                    <Form >
                                                        <Form.Check onChange={changeMethodHandler}
                                                            label="Analyze your existing patent by copy and paster to the form below"
                                                            name="method"
                                                            value="text"
                                                            type="radio"
                                                            id={`method-text`}
                                                        />
                                                        {/* <Form.Check onChange={changeMethodHandler}
                                                            label="Search for a particular patent by keyword and get report from Patent Analyzer"
                                                            name="method"
                                                            value="keyword"
                                                            type="radio"
                                                            id={`method-keyword`}
                                                        /> */}
                                                        <Form.Check onChange={changeMethodHandler}
                                                            label="Get Patent Analyzer report on some of the latest patents"
                                                            name="method"
                                                            value="number"
                                                            type="radio"
                                                            id={`method-number`}
                                                        />
                                                        <div style={{ display: method && (!results || !results.length) && (!patentListing || !patentListing.length) ? "block" : "none" }} className='mt-3'>
                                                            { method === 'text' || (method === 'keyword') ? 
                                                                <Form.Group className="mb-3" controlId="patentText" >
                                                                    { method === 'text' && 
                                                                    <div>
                                                                        <Form.Label>Please copy and paste your patent description into the form below</Form.Label>
                                                                        <Form.Control as="textarea" rows={8} value={patentText} onChange={e => setPatentText(e.target.value)}/>
                                                                    </div>
                                                                    }

                                                                    { method === 'keyword' && 
                                                                    <div>
                                                                        <Form.Label>Please enter your keyword</Form.Label>
                                                                        <Form.Control type="text" value={patentKeyword} onChange={e => setPatentKeyword(e.target.value)}/>
                                                                    </div>
                                                                    }
                                                                    <Form.Label className='mt-3'>Your email</Form.Label>
                                                                    <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                                                    { method === 'keyword' && 
                                                                        <p>
                                                                            <span className='float-start mt-3' style={{ display: isPatentListing ? "block" : "none" }}>
                                                                                <i>Please wait to get patents by keyword</i>
                                                                            </span>
                                                                            <button className='btn btn-warning float-end mt-3' disabled={isPatentListing} onClick={getPatentListing}>Next</button>
                                                                        </p>
                                                                    }
                                                                </Form.Group>
                                                                : 
                                                                <div>
                                                                    <ul style={{paddingLeft: 0 + 'rem', listStyle: 'none outside'}}>
                                                                        {
                                                                            uniCategories.map((catName, index) => (
                                                                                <li>
                                                                                    <TenLatestPost nodes={data.allWpPost.nodes} catName={ catName } onSelect={selectPatentToAnalyzer} />
                                                                                    <div style={{ display: (selectedCatName === catName) && patentNumber && patentTitle ? "block" : "none" }} className="my-3">
                                                                                        <Form.Group className="mb-3" controlId="patentNumber" >
                                                                                            <Form.Label className='mt-3'>Patent Number</Form.Label>
                                                                                            <div className='row'>
                                                                                                <div className='col-md-2'><strong>{patentNumber}</strong></div>
                                                                                                <div className='col-md-10'><strong>{patentTitle}</strong></div>
                                                                                            </div>
                                                                                            <Form.Label className='mt-3'>Your email</Form.Label>
                                                                                            <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                                                                        </Form.Group>
                                                                                        <p>
                                                                                            <span>
                                                                                                <a href="javascript: void(0);" onClick={(e) => {e.preventDefault(); unSelectPatentToAnalyzer()}}>Cancel</a>
                                                                                            </span>
                                                                                            <button className='btn btn-warning float-end' disabled={isProcessing} onClick={startAnalyzer}>Start Patent Analyzer</button>
                                                                                        </p>
                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            }
                                                            <p style={{ display: isProcessing ? "block" : "none" }} className='float-start'><i>It may take few minutes, please come back to this page later or wait for our email of the result</i></p>
                                                            <p className='float-end'><button style={{ display: method==='text' ? "block" : "none" }} 
                                                                className='btn btn-warning' disabled={isProcessing} onClick={startAnalyzer}>Start Patent Analyzer</button></p>
                                                        </div>
                                                        <button style={{ display: !method ? "block" : "none" }} className='btn btn-warning mt-3' onClick={() => void 0}>Next</button>
                                                    </Form>
                                                </div>
                                                {/* Patent listing */}
                                                <div style={{ display: patentListing && patentListing.length ? "block" : "none" }}>
                                                    <p className='mt-5'>Please select a patent number to analyse and get your report</p>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">Part Number</th>
                                                            <th scope="col">Title</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {patentListing.map(ele=>(
                                                            <tr>
                                                                <td>
                                                                    <strong>
                                                                        <a href='javascript: void(0);' onClick={() => {selectPatentNumberToAnalyzer(ele.serial)}}>{ele.serial}</a>
                                                                    </strong>
                                                                    </td>
                                                                <td>{ele.title}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    <p>
                                                        <a href='javascript:void(0);' onClick={e => backKeyword(e)}>Back</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                            <img src={AnalyzerPic1} width="100%"/>
                                            <img src={AnalyzerPic2} width="100%"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: results && results.length ? "block" : "none" }}>
                                    <div className='mt-5'>
                                        <hr></hr>
                                        <p>Thank you. Please <a href="javascript: void(0);" target="_blank">click here to download</a> the result when it becomes available. We will also send you an email with the file attached.</p>
                                    </div>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                            <th scope="col">Part Name</th>
                                            <th scope="col">Refs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {results.map(ele=>(
                                            <tr>
                                                <td>{ele.nounPhrase}</td>
                                                <td>{ele.num}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default PatentAnalyzer;
