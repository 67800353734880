import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"

const TenLatestPost = ({ nodes, catName, onSelect }) => {
    const [isActive, setIsActive] = useState(false);
    const autoBlogs = nodes.filter(node => node.categories.nodes.some(el => el.name === catName)).slice(0, 10);
    const selectHandler = (slug, title, catName) => {
        // data-processing-and-other-methods-11256777
        const patentNumber = slug.substring(slug.lastIndexOf('-') + 1);
        onSelect(patentNumber, title, catName);
        setIsActive(false);
      };
    return (
        <>
            Click here to analyzer latest patents on <a href="javascript: void(0);" onClick={(e) => {e.preventDefault(); setIsActive(!isActive)}}>{ catName }</a>
            {isActive && <div className="accordion-content">
                <ul>{
                autoBlogs.filter(node => node.categories.nodes.some(el => el.name === catName)).map((node, index) => (
                    <li>
                        <a href="javascript: void(0);" onClick={() => selectHandler(node.slug, node.title, catName)}  style={{ textDecoration: 'none' }}>
                                  {node.title}
                        </a>
                    </li>
                ))
                }</ul>
            </div>
            }
        </>
    );
};

TenLatestPost.propTypes = {
    nodes: PropTypes.node.isRequired,
    catName: PropTypes.node.isRequired,
    onSelect: PropTypes.node.isRequired,
}

export default TenLatestPost;